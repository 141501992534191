import React from 'react';
import { Box } from '@mui/material';
import * as _ from 'lodash';

export const QuizProgress = ({ backgroundColor = '#E5E7EB', filledColor = '#1E95A0', filled, total }: { total: number; filled: number; filledColor?: string; backgroundColor?: string }) => (
  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
    {_.fill(Array(total), 1)
      .map((v, i) => i < filled)
      .map((v, i) => (
        <Box
          key={i}
          sx={{
            width: 64,
            height: 8,
            borderRadius: '8px',
            backgroundColor: v ? filledColor : backgroundColor,
          }}
        />
      ))}
  </Box>
);
